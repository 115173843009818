.text-expandable {
  flex-direction: column;

  &__button-content {
    display: flex;
    align-items: center;
  }

  &__text {
    @include normal-text;
    color: $color-grey-text;
  }
  
  &__button {
    display: block;
  }
}

.text-expandable__text {

  &--board-of-directors {
    font-family: $font-family-sans-serif-small;
    font-weight: 400;
    color: $color-white;
    font-size: 16px;
    line-height: 24px;
  }
}

.center-text-single {

  .text-expandable {

    &__text {
      font-family: $font-family-serif;
      font-weight: 500;
      font-size: 24px;
      line-height: 30px;
      color: $color-dark-blue;
      text-align: center;
    }
  }
}