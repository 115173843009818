.news {

  &__intro {

    h1 {
      @include large-heading;
      color: $color-dark-blue;
      margin-top: 30px;
    }
  }
}