.main-nav {
  $self: &;

  transition: visibility 0.2s;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
  width: 100%;
  height: 100%;
  background: transparent;
  overflow: hidden;

  .nav-drop {
    list-style: none;
    padding-left: 0;

    &__item {
      font-size: 16px;
      line-height: 24px;
      font-family: $font-family-sans-serif-small;
      font-weight: 400;
      text-transform: none;
      margin-bottom: 8px;
    }

    &__link {
      color: $color-dark-blue;
      text-transform: none;
      text-decoration: none;
      transition: color 0.3s ease-out;
      display: block;
      letter-spacing: 0;

      &:hover, &:focus, &:active {
        text-decoration: none;
        color: $color-orange;
      }
    }
  }

  &__item-button {
    position: relative;
    background: none;
    padding: 0;
    border: 0;
    outline: 0;
    margin-bottom: 2px;

    &:hover, &:focus, &:active {

      .btn-expand-collapse-icon, .main-nav__link {
        text-decoration: none;
        color: $color-orange;
      }
    }

    &:focus {
      outline: 0;
    }
  }

  &__dropdown {
    display: none;
    flex-direction: column;
    margin: 0;
    position: absolute;
  }

  &__subitem {
    list-style: none;
    margin: 0;
  }

  &__sublink {
    background: $color-dark-blue;
    display: block;
    padding: 0.5em;
    text-decoration: none;
  }

  &__link {
    margin-right: 15px;
    font-size: 24px;
    line-height: 22px;
    font-family: $font-family-sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    color: $color-dark-blue;
    text-decoration: none;
    transition: color 0.3s ease-out;

    &:hover, &:focus, &:active {
      text-decoration: none;
      color: $color-orange;
    }

    @include media-breakpoint-up(md) {
      font-size: 26px;
      line-height: 26px;
    }
  }

  &__close-holder {
    height: 90px;
    display: flex;
    padding-right: 20px;
    justify-content: flex-end;
    align-items: center;
  }

  &__close {
    margin: 0;
    border: 0;
    padding: 0;
    vertical-align: middle;
    white-space: normal;
    border-radius: 0;
    background: none;
    line-height: 1;
    transition: opacity 0.2s 0.2s;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;
    font-size: 17px;
    opacity: 0;

    &:focus {
      outline: 0;
    }
  }

  &__close .icon__symbol {
    stroke: $color-dark-blue;
    fill: $color-dark-blue;
    width: 15px;
    height: 15px;
  }

  .btn-expand-collapse-icon {
    margin: 0;
    border: 0;
    padding: 0;
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    border-radius: 0;
    background: none;
    line-height: 1;
    position: absolute;
    bottom: 10px;
    right: -14px;
    -webkit-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
    width: 20px;
    height: 20px;
    color: $color-dark-blue;
    transition: background-color 0.3s ease-out;
    font: inherit;
    line-height: inherit;
  }

  .btn-expand-collapse-icon__label {
    font-size: 3.5rem;
  }

  .btn-expand-collapse__label:before {
    content: '+';
    position: relative;
    top: -3px;

    @include media-breakpoint-up(md) {
      top: -6px;
    }
  }

  [aria-expanded="true"] {
    .btn-expand-collapse__label:before {
      content: '\2212';
    }
  }

  .main-nav__item {

    &.is-open {

      #{$self}__link {
        color: $color-light-orange;
      }

      .btn-expand-collapse-icon {
        color: $color-light-orange;
      }
    }
  }

  @include media-breakpoint-up(md) {
    &__close {
      width: 4rem;
    }
  }

  @include media-breakpoint-up(sm) {
    &__close {
      font-size: 2rem;
    }
  }

  @keyframes FadeIn {
    0% {
      opacity: 0;
      transform: translateY(15px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &.is-active {
    visibility: visible;

    #{$self}__overlay {
      opacity: 1;
    }

    #{$self}__bg {
      -webkit-transform: translateX(0);
      transform: translateX(0);
    }

    #{$self}__close {
      opacity: 1;
    }

    #{$self}__item {
      animation: FadeIn 0.4s linear;

      &:nth-child(1) {
        transition-delay: 0.2s;
        animation-delay: 0.2s;
      }

      &:nth-child(2) {
        transition-delay: 0.4s;
        animation-delay: 0.4s;
      }

      &:nth-child(3) {
        transition-delay: 0.6s;
        animation-delay: 0.6s;
      }

      &:nth-child(4) {
        transition-delay: 0.8s;
        animation-delay: 0.8s;
      }

      &:nth-child(5) {
        transition-delay: 1s;
        animation-delay: 1s;
      }

      &:nth-child(6) {
        transition-delay: 1.2s;
        animation-delay: 1.2s;
      }

      &:nth-child(7) {
        transition-delay: 1.4s;
        animation-delay: 1.4s;
      }
    }

    #{$self}__contact {
      animation: FadeIn 0.4s linear;
      transition-delay: 1.6s;
      animation-delay: 1.6s;
    }

    #{$self}__social {
      animation: FadeIn 0.4s linear;
      transition-delay: 1.8s;
      animation-delay: 1.8s;
    }
  }

  &__social {

    .social-list {
      padding-left: 0;

      &__item:first-child {
        margin-left: 0;
      }
    }
  }

  &__contact {
    margin-top: auto;
    margin-bottom: 20px;
  }
  &__contact-list {
    padding-left: 0;
    list-style: none;
  }

  &__contact-list-item {
    font-family: $font-family-sans-serif-small;
    font-weight: 400;
    color: $color-dark-blue;
    font-size: 14px;
    line-height: 16px;
  }

  &.is-leave {
    transition-delay: 0.4s;

    #{$self}__overlay {
      transition-delay: 0.4s;
    }

    #{$self}__bg {
      transition-delay: 0s;
    }

    #{$self}__close {
      transition-delay: 0s;
    }
  }

  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: opacity 0.2s;
    opacity: 0;
    background: rgba(255, 255, 255, 0.7);
  }

  &__list {
    list-style: none;
    text-align: left;
    padding-left: 0;
  }

  &__bg {
    transition: -webkit-transform 0.8s 0s ease;
    transition: transform 0.8s 0s ease;
    transition: transform 0.8s 0s ease, -webkit-transform 0.8s 0s ease;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    width: 80%;
    height: 100%;
    padding: 60px 25px;
    background: $color-white;
  }

  &__item {
    margin: 0 0 10px;
    font-size: 24px;
    line-height: 22px;
    font-family: $font-family-sans-serif;
    font-weight: 500;
    color: $color-dark-blue;
    letter-spacing: 1px;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      font-size: 26px;
      line-height: 26px;
    }
  }

  @include media-breakpoint-up(md) {
    &__bg {
      width: 25%;
      padding: 75px 35px;
    }
  }
}
