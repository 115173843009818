.property-details {
  padding-top: 0;
  padding-bottom: 30px;

  &__label {
    font-family: $font-family-sans-serif-small;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: $color-dark-blue;
    margin-bottom: 5px;
  }

  &__detail {
    font-family: $font-family-sans-serif-small;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $color-grey-text;
    margin-bottom: 40px;
  }

  &__text {
    @include normal-text;
    color: $color-grey-text;
  }
}