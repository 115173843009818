.financial-and-annual-reports {
  padding-top: 0;
  padding-bottom: 0;

  @include media-breakpoint-up(md) {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  &__img-col {
    padding-bottom: 50px;

    @include media-breakpoint-up(md) {
      padding-bottom: 0;
    }
  }
}