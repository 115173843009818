.contact {
  padding-top: 50px;

  &__list {
    list-style: none;
    padding-left: 0;
    padding-top: 30px;
  }

  &__list-item {
    line-height: 28px;
    display: flex;
    margin-bottom: 10px;

    a {
      color: $color-dark-blue;
      transition: all 0.3s;
      text-decoration: underline;

      &:hover, &:focus, &:active {
        color: $color-orange;
        text-decoration: underline;
      }
    }
  }

  &__list-item-title {
    width: 30%;
    display: inline-block;
    @include normal-text;
    font-weight: 500;
    color: $color-dark-blue;

    @include media-breakpoint-up(md) {
      width: 140px;
    }
  }

  &__list-item-info {
    display: inline-block;
  }

  .social-list {
    padding-top: 20px;
  }
}