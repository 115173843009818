.gallery-slider {
  padding-top: 20px;
  padding-bottom: 100px;

  @include media-breakpoint-up(md) {
    padding-top: 50px;
  }

  .carousel-number-indicators {
    position: absolute;
    bottom: -51px;
  }

  .carousel-indicators {
    bottom: -66px;

    li {
      background-color: $color-dark-blue;
      opacity: 1;

      &.active {
        background-color: $color-light-orange;
      }
    }
  }

  .carousel-control-next {
    background-image: linear-gradient(to right, rgba(0,0,0,0), rgba(0,0,0,0.2));
    width: 12%;
  }

  .carousel-control-prev {
    background-image: linear-gradient(to left, rgba(0,0,0,0), rgba(0,0,0,0.2));
    width: 12%;
  }

  .arrow {
    width: 30px;
    height: 30px;
    border-top: 3px solid $color-white;
    border-right: 3px solid $color-white;

    &.right {
      left: 32%;
      transform: rotate(45deg);
    }

    &.left {
      left: 37%;
      transform: rotate(-135deg);
    }
  }
}
