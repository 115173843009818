.intro {
  padding-top: 50px;
  padding-bottom: 50px;

  @include media-breakpoint-up(md) {
    padding-top: 120px;
    padding-bottom: 40px;
  }

  &__heading {
    @include large-heading;
    color: $color-dark-blue;
  }
}