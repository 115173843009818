.recent-news {
  $self: &;

  padding-top: 20px;
  padding-bottom: 60px;

  &__item {

    a {
      text-decoration: none;
    }
  }

  &__card {
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
    @include hover-shadow;

    @include media-breakpoint-up(md) {
      margin-bottom: 20px;
      flex-direction: column;
    }

    img {
      width: 100%;
      margin-bottom: auto;
      align-self: center;
    }
  }

  &__card-img-wrapper {
    overflow: hidden;
    width: 40%;

    @include media-breakpoint-up(md) {
      width: 100%;
    }
  }

  &__card-body {
    padding: 0 20px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 60%;

    @include media-breakpoint-up(md) {
      width: 100%;
      padding: 30px 20px 0 20px;
      min-height: 140px;
      justify-content: flex-start;
    }
  }

  &__card-body-category {
    display: none;
    font-family: $font-family-sans-serif;
    font-weight: 600;
    letter-spacing: 2px;
    color: $color-light-orange;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 19px;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  &__card-body-title {
    display: block;
    font-family: $font-family-serif;
    font-weight: 500;
    color: $color-dark-blue;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 0;
    transition: all 0.3s ease-out;
  }

  &__card-img {
    transition: all 0.3s ease-out;
  }

  @include media-breakpoint-up(md) {
    &__card {
      margin-bottom: 0;

      img {
        width: 100%;
      }
    }

    &__item {
      position: relative;
    }

    &__card-body-text {
      display: block;
    }
  }
}
