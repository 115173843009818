.icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;

  &__symbol {
    display: block;
    width: 15px;
    height: 15px;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  }

  &--circled {
    width: 25px;
    /* ensure right proportions with respect to icon size */
    height: 25px;
    border: 2px solid currentColor;
    border-radius: 50%;
  }
}
