.icon-circled {
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: 15px;
  transition: 300ms ease-out;
  border: 2px solid $color-light-orange;

  .arrow {
    position: absolute;
    top: 35%;
    left: 32%;
    border-top: 2px solid $color-orange;
    border-right: 2px solid $color-orange;
    border-radius: 0;
    width: 30%;
    height: 30%;
    transform: rotate(45deg);
    transition: 200ms ease-out;

    &.down {
      left: 38%;
      transform: rotate(135deg);
    }

    &.left {
      left: 37%;
      transform: rotate(-135deg);
    }

    &.up {
      left: 36%;
      top: 40%;
      transform: rotate(315deg);
    }
  }
}

.btn:hover .icon-circled {
  transform: translateX(10px);
}

.btn:hover .icon-circled {

  &--no-hover {
    transform: none;
  }
}

.btn-download {
  -webkit-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid #3f51b5;
    z-index: -1;
    border-radius: inherit;
    opacity: 0;
    -webkit-transform: scale3d(0.6, 0.6, 1);
    transform: scale3d(0.6, 0.6, 1);
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
    transition: transform 0.3s, opacity 0.3s;
    -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
    transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  }

  &:hover {
    background-color: $color-dark-blue;
  }

  &:hover::before {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }

  &:before {
    border-color: #7986cb;
  }

  .icon-circled {
      width: 25px;
      height: 25px;
      margin-left: 25px;
      border: 2px solid $color-white;

    .arrow {
      border-top: 2px solid $color-white;
      border-right: 2px solid $color-white;
    }
  }
}

.btn-back-to-top {

  .icon-circled {
    width: 40px;
    height: 40px;
    margin-left: 0;
    border: 2px solid $color-dark-blue;

    .arrow {
      border-top: 2px solid $color-dark-blue;
      border-right: 2px solid $color-dark-blue;
    }
  }
}
