.table-pdf-download {
    background-color: $color-white;
    margin-bottom: 60px;

    thead {
        background-color: $color-dark-blue;

        th {
            font-family: $font-family-sans-serif-small;
            font-weight: 500;
            color: $color-white;
            font-size: 18px;
            line-height: 21px;
            padding: 21px 10px 21px 15px;
            border-bottom: 0;

            @include media-breakpoint-up(md) {
                padding: 21px 10px 21px 32px;
            }
        }
    }

    tbody {
        tr {

            &:hover {
                background-color: $color-grey-light-hover;
            }
        }
    }

    &__file-title-text {
        width: 82%;
        display: inline-flex;

        @include media-breakpoint-up(md) {
            width: 78%;
        }

        &:focus, &:hover, &:active {
            text-decoration: none;
        }
    }

    &__file-title-pdf {
        width: 18%;
        text-decoration: underline;

        .icon-circled {
            margin-left: 0;
        }

        @include media-breakpoint-up(md) {
            width: 22%;

            .icon-circled {
                margin-left: 15px;
            }
        }

        &:focus, &:hover, &:active {
            text-decoration: underline;
        }
    }

    &__file-btn-text {
        display: none;

        @include media-breakpoint-up(md) {
            display: inline;
            transition: color 0.3s ease-out;
        }
    }

    td {
        @include normal-text;
        color: $color-dark-blue;
        vertical-align: middle;

        @include media-breakpoint-up(md) {
            font-size: 18px;
            padding-left: 32px;
        }

        a {
            @include normal-text;
            color: $color-dark-blue;
            text-decoration: underline;
            color: $color-dark-blue;
            width: 100%;
            text-decoration: none;
            text-align: left;

            @include media-breakpoint-up(md) {
                font-size: 18px;
            }
        }
    }

    &.table-pdf-report-download {

        .table-pdf-download__report-type-text {
            width: 50%;
        }

        .table-pdf-download__file-btn-pdf {
            width: 30%;
        }

        .table-pdf-download__year-text {
            width: 20%;
            text-align: left;
        }
    }

    &.table-pdf-forms-download {

        .table-pdf-download__file-title-section {
            width: 90%;
            padding-right: 30px;
        }

        .table-pdf-download__file-title {
            display: block;
            margin-bottom: 20px;
        }

        .table-pdf-download__file-info-text {
            @include normal-text;
            color: $color-grey-text;
            display: block;
        }

        .table-pdf-download__file-title-pdf {
            width: 20%;
            margin-bottom: auto;
        }
    }
}
