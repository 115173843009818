.heading-underlined {
  padding-top: 20px;
  padding-bottom: 20px;

  &__title {
    font-family: $font-family-sans-serif;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 2.24px;
    color: $color-light-orange;
    width: 100%;
    padding-bottom: 15px;
    border-bottom: 2px solid $color-dark-blue;
  }
}
