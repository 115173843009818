.single-news {

  &__article-intro-title {
    font-family: $font-family-serif;
    font-weight: 500;
    font-size: 45px;
    line-height: 58px;
    color: $color-dark-blue;
    margin-bottom: 30px;
  }

  &__article-intro-author {
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      margin-bottom: 50px;
    }
  }

  &__article-intro-author-name-by {
   @include normal-text;
    font-weight: 400;
    color: $color-dark-blue;
    margin-left: 0;

    @include media-breakpoint-up(md) {
      margin-left: 25px;
    }
  }

  &__article-intro-author-name-main {
    @include normal-text;
    font-weight: 600;
    color: $color-dark-blue;
  }

  &__article-content-main-image {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &__article-content-main {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}