.investors {
  $self: &;

  &__share-price-section {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  &__share-price-title {
    font-family: $font-family-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    color: $color-dark-blue;
  }

  &__share-price-container {
    height: 74px;
  }

  &__share-price {
    font-family: $font-family-sans-serif-small;
    font-weight: 700;
    font-size: 50px;
    line-height: 58px;
    color: $color-light-orange;
    display: inline-block;
  }

  &__share-price-change {
    font-family: $font-family-sans-serif-small;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $color-dark-blue;
    margin-left: 15px;
    display: inline-block;
  }

  &__share-price-info {
    font-family: $font-family-sans-serif-small;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $color-dark-blue;
    display: block;
  }

  &__share-price-disclaimer {
    font-family: $font-family-sans-serif-small;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $color-dark-blue;
  }

  &__link {
    margin-top: 100px;
  }

  &__link-tile {
    display: inline-block;
    text-align: center;
    overflow: hidden;

    img {
      //will-change: transform;
      //-webkit-transform: translate3d(0, 0, 1px);
      //transform: translate3d(0, 0, 1px);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -webkit-transition: -webkit-transform 0.3s ease-out;
      transition: -webkit-transform 0.3s ease-out;
      -o-transition: transform 0.3s ease-out;
      transition: transform 0.3s ease-out;
      transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    }

    //&:hover {
    //
    //
    //  img {
    //    -webkit-transform: scale(1.05);
    //    -ms-transform: scale(1.05);
    //    transform: scale(1.05);
    //  }
    //}

    &:hover {
      -webkit-box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.25);
      box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.25);
      -webkit-transform: translateY(3px);
      -ms-transform: translateY(3px);
      transform: translateY(3px);
      background: $color-grey-light-hover;

      #{$self}__tile-img {
        -webkit-transform: scale(1.05);
        -ms-transform: scale(1.05);
        transform: scale(1.05);
      }
    }
  }

  &__tile {
    position: relative;
  }

  &__tile-img {
    will-change: transform;
    -webkit-transform: translate3d(0, 0, 1px);
    transform: translate3d(0, 0, 1px);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: transform 0.3s;
    -o-transition: transform 0.3s;
    transition: transform 0.3s;
  }

  &__tile-title {
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: 2px;
    color: $color-white;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 60%;

    &:hover, &:focus, &:active {
      text-decoration: none;
      color: $color-white;
    }
  }
}