.our-community {

  .our-community__ways-we-help-container {

    .container {
      position: relative;
    }

    .container:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 15px;
      height: 2px;
      background: $color-grey-super-light;
      width: calc(100% - 30px);
    }

    &--no-bottom-border {
      .container:after {
        display: none;
      }
    }
  }
}