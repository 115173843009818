.no-js {

  .content-loading-animation {
    display: none;
  }
}

body {
  background-color: $color-grey-background;
  font-family: $font-family-sans-serif-small;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: $color-grey-main-text;
}

p {
  font-family: $font-family-sans-serif-small;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: $color-grey-main-text;
}

h1 {
  @include large-heading;
  color: $color-dark-blue;
}

main {
  position: relative;
  padding-top: $navbar-height;
  padding-bottom: 150px;
  z-index: 1;
  background-color: $color-grey-background;
}

@mixin container-padding($paddingMobile, $paddingDesktop) {
  padding-top: $paddingMobile;
  padding-bottom: $paddingMobile;

  @include media-breakpoint-up(md) {
    padding-top: $paddingDesktop;
    padding-bottom: $paddingDesktop;
  }
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  > .col,
  > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.padding-60 {
  @include container-padding(40px, 60px);
}

.padding-80 {
  @include container-padding(80px, 80px);
}

@mixin hover-shadow {
  -webkit-box-shadow: 0 5px 18px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 18px 3px rgba(0, 0, 0, 0.1);
  -webkit-transition: background 0.1s, -webkit-box-shadow 0.1s ease-out, -webkit-transform 0.1s ease-out;
  transition: background 0.1s, -webkit-box-shadow 0.1s ease-out, -webkit-transform 0.1s ease-out;
  -o-transition: box-shadow 0.1s ease-out, transform 0.1s ease-out, background 0.1s;
  transition: box-shadow 0.1s ease-out, transform 0.1s ease-out, background 0.1s;
  transition: box-shadow 0.1s ease-out, transform 0.1s ease-out, background 0.1s, -webkit-box-shadow 0.1s ease-out, -webkit-transform 0.1s ease-out;
  background: $color-white;
  overflow: hidden;

  img {
    will-change: transform;
    -webkit-transform: translate3d(0, 0, 1px);
    transform: translate3d(0, 0, 1px);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    -o-transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  }

  &:hover {
    -webkit-box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.25);
    box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.25);
    -webkit-transform: translateY(3px);
    -ms-transform: translateY(3px);
    transform: translateY(3px);
    background: $color-grey-light-hover;

    img {
      -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
      transform: scale(1.05);
    }
  }
}

.content-loaded {

  .content-loading-animation {
    height: 0;
  }
}

.page-template-archive-investors-asx-announcements {

  .content-loading-animation {
    height: 0;
  }
}

.content-loading-animation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 9999999999;
  background-color: $color-orange;
  -webkit-transition: all 1s cubic-bezier(0.37, 0.01, 0, 0.98);
  -o-transition: all 1s cubic-bezier(0.37, 0.01, 0, 0.98);
  transition: all 1s cubic-bezier(0.37, 0.01, 0, 0.98);

  &.content-page-transition-active {
    height: 100%;
  }
}
