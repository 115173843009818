.also-see {
  $self: &;

  padding-top: 20px;

  &__card-main-link {
    display: inline-flex;
    text-decoration: none;

    &:hover, &:focus, &:active {
      text-decoration: none;
    }
  }

  &__card {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    box-shadow: 0 5px 18px 3px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.1s ease-out, transform 0.1s ease-out, background 0.1s;
    background: $color-white;

    &:hover {
      box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.25);
      transform: translateY(3px);
      background: $color-grey-light-hover;
    }

    img {
      width: 40%;
      margin-bottom: auto;
      align-self: center;
    }
  }

  &__card-img {
    transition: all 0.3s;
  }

  &__card-body {
    padding: 0 20px;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  &__card-body-title {
    font-family: $font-family-serif;
    font-weight: 500;
    color: $color-dark-blue;
    font-size: 18px;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 0;
  }

  &__card-body-text {
    display: none;
  }

  @include media-breakpoint-up(md) {

    &__card-body-text {
      @include normal-text;
      color: $color-grey-text;
      margin-bottom: 60px;
      display: block;
    }

    &__card-body-title {
      font-size: 45px;
      line-height: 58px;
      margin-bottom: 20px;
    }

    &__card-body {
      padding: 0 90px;
    }

    &__card {
      margin-bottom: 0;

      img {
        width: 50%;
      }
    }
  }
}