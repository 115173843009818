.footer {
  margin-top: -25px;

  &__nav-list {
    padding-left: 0;
    list-style: none;
  }

  &__nav-list-item {
    a {
      font-family: $font-family-sans-serif-small;
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: $color-grey-main-text;
      transition: color 0.3s ease-out;

      &:hover, &:focus, &:active {
        text-decoration: none;
        color: $color-orange;
      }
    }
  }

  &__copyright {
    p {
      font-family: $font-family-sans-serif-small;
      font-weight: 400;
      color: $color-grey-light-text;
      font-size: 13px;
      line-height: 15px;
    }

    a {
      font-family: $font-family-sans-serif-small;
      font-weight: 400;
      color: $color-grey-light-text;
      font-size: 13px;
      line-height: 15px;
      transition: color 0.3s ease-out;

      &:hover, &:active, &:focus {
        color: $color-orange;
        text-decoration: none;
      }
    }
  }

  &__shadow {
    height: 25px;
    margin-bottom: 25px;
  }

  &__back-to-top {
    text-align: right;
    padding-top: 0;
  }
}