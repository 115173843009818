.parallax-background-image {
  background-attachment: fixed!important;
  background-size: cover!important;
  min-height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-up(md) {
    min-height: 400px;
  }

  &__title {
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 19px;
    letter-spacing: 2.22px;
    color: $color-white;
    margin-bottom: 30px;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      font-size: 16px;
      line-height: 19px;
    }
  }

  &__text {
    font-family: $font-family-serif;
    font-weight: 500;
    font-size: 25px;
    line-height: 27px;
    color: $color-white;

    @include media-breakpoint-up(md) {
      font-size: 45px;
      line-height: 58px;
    }
  }
}

