.background-graphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: top left;
  background-repeat: repeat-y;
  background-size: cover;
  z-index: -1;
}