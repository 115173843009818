.news-item {
  $self: &;

  padding-top: 25px;
  padding-bottom: 25px;

  @include media-breakpoint-up(md) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &__card {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    box-shadow: 0 5px 18px 3px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.1s ease-out, transform 0.1s ease-out, background 0.1s;
    background: $color-white;

    @include media-breakpoint-up(md) {
      margin-bottom: 20px;
    }

    &:hover {
      box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.25);
      transform: translateY(3px);
      background: $color-grey-light-hover;
    }

    img {
      width: 100%;
      margin-bottom: 30px;
      align-self: center;
    }
  }

  &__card-main-link {
    display: inline-flex;
    flex-direction: column;
    text-decoration: none;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    &:hover, &:focus, &:active {
      text-decoration: none;
    }
  }

  &__card-img {
    transition: all 0.3s ease-out;
  }

  &__card-body {
    padding: 0 30px;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  &__card-body-title {
    font-family: $font-family-serif;
    font-weight: 400;
    color: $color-dark-blue;
    font-size: 25px;
    line-height: 30px;
  }

  &__card-body-text {
    display: none;
  }

  &__link {
    margin-bottom: 20px;
  }

  @include media-breakpoint-up(md) {

    &__link {
      margin-bottom: 0;
    }

    &__card-body-text {
      @include normal-text;
      color: $color-grey-text;
      margin-bottom: 40px;
      display: block;
    }

    &__card-body {
      padding: 0 70px;
    }

    &__card-body-title {
      font-size: 35px;
      line-height: 40px;
      margin-bottom: 10px;
    }

    &__card {
      flex-direction: row;
      margin-bottom: 0;

      img {
        width: 50%;
        margin-bottom: auto;
      }
    }
  }
}