/*
  Project: Desane Website
  Author: Kieran Jones
 */
@import url(../../node_modules/bootstrap/dist/css/bootstrap.min.css);
@font-face {
  font-family: 'BebasNeue-BebasNeueBold';
  src: url("../assets/fonts/BebasNeue/eot/BebasNeueBold.eot");
  src: url("../assets/fonts/BebasNeue/eot/BebasNeueBold.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/BebasNeue/woff/BebasNeueBold.woff") format("woff"), url("../assets/fonts/BebasNeue/ttf/BebasNeueBold.ttf") format("truetype"), url("../assets/fonts/FuturBebasNeueaNew/svg/BebasNeueBold.svg") format("svg");
  font-style: normal;
}

@font-face {
  font-family: 'DomaineDisplay-DomaineDispMedium';
  src: url("../assets/fonts/DomaineDisplay/eot/DomaineDispMedium.eot");
  src: url("../assets/fonts/DomaineDisplay/eot/DomaineDispMedium.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/DomaineDisplay/woff/DomaineDispMedium.woff") format("woff"), url("../assets/fonts/DomaineDisplay/ttf/DomaineDispMedium.ttf") format("truetype"), url("../assets/fonts/DomaineDisplay/svg/DomaineDispMedium.svg") format("svg");
  font-style: normal;
}

@font-face {
  font-family: 'DomaineDisplay-DomaineDispMedium-Italic';
  src: url("../assets/fonts/DomaineDisplay/eot/DomaineDispMedium-Italic.eot");
  src: url("../assets/fonts/DomaineDisplay/eot/DomaineDispMedium-Italic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/DomaineDisplay/woff/DomaineDispMedium-Italic.woff") format("woff"), url("../assets/fonts/DomaineDisplay/ttf/DomaineDispMedium-Italic.ttf") format("truetype"), url("../assets/fonts/DomaineDisplay/svg/DomaineDispMedium-Italic.svg") format("svg");
  font-style: normal;
}

.no-js .content-loading-animation {
  display: none;
}

body {
  background-color: #ecebeb;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #4A4A4A;
}

p {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #4A4A4A;
}

h1 {
  font-family: "DomaineDisplay-DomaineDispMedium", serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 45px;
  color: #00013a;
}

@media (min-width: 768px) {
  h1 {
    font-size: 70px;
    line-height: 82px;
  }
}

main {
  position: relative;
  padding-top: 80px;
  padding-bottom: 150px;
  z-index: 1;
  background-color: #ecebeb;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.padding-60 {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .padding-60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.padding-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

@media (min-width: 768px) {
  .padding-80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.content-loaded .content-loading-animation {
  height: 0;
}

.page-template-archive-investors-asx-announcements .content-loading-animation {
  height: 0;
}

.content-loading-animation {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: 9999999999;
  background-color: #f99d2a;
  -webkit-transition: all 1s cubic-bezier(0.37, 0.01, 0, 0.98);
  -o-transition: all 1s cubic-bezier(0.37, 0.01, 0, 0.98);
  transition: all 1s cubic-bezier(0.37, 0.01, 0, 0.98);
}

.content-loading-animation.content-page-transition-active {
  height: 100%;
}

.heading-underlined {
  padding-top: 20px;
  padding-bottom: 20px;
}

.heading-underlined__title {
  font-family: "BebasNeue-BebasNeueBold", sans-serif;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 2.24px;
  color: #ff9e18;
  width: 100%;
  padding-bottom: 15px;
  border-bottom: 2px solid #00013a;
}

.navbar {
  border-bottom: 1px solid white;
  height: 80px;
  background: #ecebeb;
  transition: all 0.3s;
}

.navbar .navbar-brand__img-white {
  display: none !important;
}

.navbar .hamburger-button span {
  background-color: #00013a;
}

.navbar .hamburger-button:focus {
  outline: 0;
}

.navbar--transparent {
  background: transparent;
}

.navbar--transparent .hamburger-button:before {
  color: #ffffff;
}

.navbar--transparent .hamburger-button span {
  background-color: #ffffff;
}

.navbar--transparent .navbar-brand__img-blue {
  display: none !important;
}

.navbar--transparent .navbar-brand__img-white {
  display: inline-block !important;
}

.navbar--transparent.navbar-scrolled .navbar-brand__img-white {
  display: none !important;
}

.navbar--transparent.navbar-scrolled .navbar-brand__img-blue {
  display: inline-block !important;
}

.navbar.navbar-scrolled {
  background-color: #ffffff;
  height: 72px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.navbar.navbar-scrolled .hamburger-button:before {
  color: #00013a;
}

.navbar.navbar-scrolled .hamburger-button span {
  background-color: #00013a;
}

.navbar.navbar-scrolled .navbar-brand__img-white {
  display: none !important;
}

.navbar.navbar-scrolled .navbar-brand__img-blue {
  display: inline-block !important;
}

.hamburger-button {
  width: 24px;
  height: 28px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  z-index: 100;
  padding: 0;
  background: none;
  border: 0;
  outline: 0;
  color: #ffffff;
  font-size: 14px;
  line-height: 28px;
}

.hamburger-button:before {
  content: 'Menu';
  position: absolute;
  left: -50px;
  top: -3px;
  color: #00013a;
}

.hamburger-button span {
  background: #ffffff;
  display: block;
  position: absolute;
  height: 2px;
  transform: rotate(0deg);
  transition: all 0.25s cubic-bezier(0.22, 0.61, 0.36, 1);
  opacity: 1;
  width: 100%;
  left: 0;
}

.hamburger-button span:nth-child(1) {
  top: 6px;
}

.hamburger-button span:nth-child(2) {
  top: 11px;
}

.hamburger-button span:nth-child(3) {
  top: 16px;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: none;
  transition: all 0.2s;
  z-index: 12;
}

.sidebar-overlay.is-active {
  display: block;
  opacity: 1;
}

.pagination {
  width: 100%;
  justify-content: center;
  margin-top: 40px;
}

.pagination__prev {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #00013a;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.pagination__pages {
  list-style-type: none;
  display: flex;
  padding-left: 0;
}

.pagination__pages-item {
  display: flex;
  flex: 1;
}

.pagination__pages-item a {
  text-decoration: none;
}

.pagination__pages-item-link.page-number {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #00013a;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 39px;
  height: 39px;
}

.pagination__pages-item-span.page-number {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #00013a;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 39px;
  height: 39px;
}

.pagination__pages-item-link.page-number.current {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #00013a;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 39px;
  background: #ffffff;
  height: 39px;
}

.pagination__pages-item-span.page-number.current {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #00013a;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 39px;
  background: #ffffff;
  height: 39px;
}

.pagination__next {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #00013a;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.main-nav {
  transition: visibility 0.2s;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10001;
  width: 100%;
  height: 100%;
  background: transparent;
  overflow: hidden;
}

.main-nav .nav-drop {
  list-style: none;
  padding-left: 0;
}

.main-nav .nav-drop__item {
  font-size: 16px;
  line-height: 24px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  text-transform: none;
  margin-bottom: 8px;
}

.main-nav .nav-drop__link {
  color: #00013a;
  text-transform: none;
  text-decoration: none;
  transition: color 0.3s ease-out;
  display: block;
  letter-spacing: 0;
}

.main-nav .nav-drop__link:hover, .main-nav .nav-drop__link:focus, .main-nav .nav-drop__link:active {
  text-decoration: none;
  color: #f99d2a;
}

.main-nav__item-button {
  position: relative;
  background: none;
  padding: 0;
  border: 0;
  outline: 0;
  margin-bottom: 2px;
}

.main-nav__item-button:hover .btn-expand-collapse-icon, .main-nav__item-button:hover .main-nav__link, .main-nav__item-button:focus .btn-expand-collapse-icon, .main-nav__item-button:focus .main-nav__link, .main-nav__item-button:active .btn-expand-collapse-icon, .main-nav__item-button:active .main-nav__link {
  text-decoration: none;
  color: #f99d2a;
}

.main-nav__item-button:focus {
  outline: 0;
}

.main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;
}

.main-nav__subitem {
  list-style: none;
  margin: 0;
}

.main-nav__sublink {
  background: #00013a;
  display: block;
  padding: 0.5em;
  text-decoration: none;
}

.main-nav__link {
  margin-right: 15px;
  font-size: 24px;
  line-height: 22px;
  font-family: "BebasNeue-BebasNeueBold", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: #00013a;
  text-decoration: none;
  transition: color 0.3s ease-out;
}

.main-nav__link:hover, .main-nav__link:focus, .main-nav__link:active {
  text-decoration: none;
  color: #f99d2a;
}

@media (min-width: 768px) {
  .main-nav__link {
    font-size: 26px;
    line-height: 26px;
  }
}

.main-nav__close-holder {
  height: 90px;
  display: flex;
  padding-right: 20px;
  justify-content: flex-end;
  align-items: center;
}

.main-nav__close {
  margin: 0;
  border: 0;
  padding: 0;
  vertical-align: middle;
  white-space: normal;
  border-radius: 0;
  background: none;
  line-height: 1;
  transition: opacity 0.2s 0.2s;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 1;
  font-size: 17px;
  opacity: 0;
}

.main-nav__close:focus {
  outline: 0;
}

.main-nav__close .icon__symbol {
  stroke: #00013a;
  fill: #00013a;
  width: 15px;
  height: 15px;
}

.main-nav .btn-expand-collapse-icon {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  border-radius: 0;
  background: none;
  line-height: 1;
  position: absolute;
  bottom: 10px;
  right: -14px;
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
  width: 20px;
  height: 20px;
  color: #00013a;
  transition: background-color 0.3s ease-out;
  font: inherit;
  line-height: inherit;
}

.main-nav .btn-expand-collapse-icon__label {
  font-size: 3.5rem;
}

.main-nav .btn-expand-collapse__label:before {
  content: '+';
  position: relative;
  top: -3px;
}

@media (min-width: 768px) {
  .main-nav .btn-expand-collapse__label:before {
    top: -6px;
  }
}

.main-nav [aria-expanded="true"] .btn-expand-collapse__label:before {
  content: '\2212';
}

.main-nav .main-nav__item.is-open .main-nav__link {
  color: #ff9e18;
}

.main-nav .main-nav__item.is-open .btn-expand-collapse-icon {
  color: #ff9e18;
}

@media (min-width: 768px) {
  .main-nav__close {
    width: 4rem;
  }
}

@media (min-width: 576px) {
  .main-nav__close {
    font-size: 2rem;
  }
}

@keyframes FadeIn {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.main-nav.is-active {
  visibility: visible;
}

.main-nav.is-active .main-nav__overlay {
  opacity: 1;
}

.main-nav.is-active .main-nav__bg {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.main-nav.is-active .main-nav__close {
  opacity: 1;
}

.main-nav.is-active .main-nav__item {
  animation: FadeIn 0.4s linear;
}

.main-nav.is-active .main-nav__item:nth-child(1) {
  transition-delay: 0.2s;
  animation-delay: 0.2s;
}

.main-nav.is-active .main-nav__item:nth-child(2) {
  transition-delay: 0.4s;
  animation-delay: 0.4s;
}

.main-nav.is-active .main-nav__item:nth-child(3) {
  transition-delay: 0.6s;
  animation-delay: 0.6s;
}

.main-nav.is-active .main-nav__item:nth-child(4) {
  transition-delay: 0.8s;
  animation-delay: 0.8s;
}

.main-nav.is-active .main-nav__item:nth-child(5) {
  transition-delay: 1s;
  animation-delay: 1s;
}

.main-nav.is-active .main-nav__item:nth-child(6) {
  transition-delay: 1.2s;
  animation-delay: 1.2s;
}

.main-nav.is-active .main-nav__item:nth-child(7) {
  transition-delay: 1.4s;
  animation-delay: 1.4s;
}

.main-nav.is-active .main-nav__contact {
  animation: FadeIn 0.4s linear;
  transition-delay: 1.6s;
  animation-delay: 1.6s;
}

.main-nav.is-active .main-nav__social {
  animation: FadeIn 0.4s linear;
  transition-delay: 1.8s;
  animation-delay: 1.8s;
}

.main-nav__social .social-list {
  padding-left: 0;
}

.main-nav__social .social-list__item:first-child {
  margin-left: 0;
}

.main-nav__contact {
  margin-top: auto;
  margin-bottom: 20px;
}

.main-nav__contact-list {
  padding-left: 0;
  list-style: none;
}

.main-nav__contact-list-item {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #00013a;
  font-size: 14px;
  line-height: 16px;
}

.main-nav.is-leave {
  transition-delay: 0.4s;
}

.main-nav.is-leave .main-nav__overlay {
  transition-delay: 0.4s;
}

.main-nav.is-leave .main-nav__bg {
  transition-delay: 0s;
}

.main-nav.is-leave .main-nav__close {
  transition-delay: 0s;
}

.main-nav__overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.2s;
  opacity: 0;
  background: rgba(255, 255, 255, 0.7);
}

.main-nav__list {
  list-style: none;
  text-align: left;
  padding-left: 0;
}

.main-nav__bg {
  transition: -webkit-transform 0.8s 0s ease;
  transition: transform 0.8s 0s ease;
  transition: transform 0.8s 0s ease, -webkit-transform 0.8s 0s ease;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 80%;
  height: 100%;
  padding: 60px 25px;
  background: #ffffff;
}

.main-nav__item {
  margin: 0 0 10px;
  font-size: 24px;
  line-height: 22px;
  font-family: "BebasNeue-BebasNeueBold", sans-serif;
  font-weight: 500;
  color: #00013a;
  letter-spacing: 1px;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .main-nav__item {
    font-size: 26px;
    line-height: 26px;
  }
}

@media (min-width: 768px) {
  .main-nav__bg {
    width: 25%;
    padding: 75px 35px;
  }
}

.footer {
  margin-top: -25px;
}

.footer__nav-list {
  padding-left: 0;
  list-style: none;
}

.footer__nav-list-item a {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #4A4A4A;
  transition: color 0.3s ease-out;
}

.footer__nav-list-item a:hover, .footer__nav-list-item a:focus, .footer__nav-list-item a:active {
  text-decoration: none;
  color: #f99d2a;
}

.footer__copyright p {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #9B9B9B;
  font-size: 13px;
  line-height: 15px;
}

.footer__copyright a {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #9B9B9B;
  font-size: 13px;
  line-height: 15px;
  transition: color 0.3s ease-out;
}

.footer__copyright a:hover, .footer__copyright a:active, .footer__copyright a:focus {
  color: #f99d2a;
  text-decoration: none;
}

.footer__shadow {
  height: 25px;
  margin-bottom: 25px;
}

.footer__back-to-top {
  text-align: right;
  padding-top: 0;
}

.split-section-image-left {
  padding-top: 50px;
  padding-bottom: 50px;
}

.split-section-image-left__title {
  font-family: "DomaineDisplay-DomaineDispMedium", serif;
  font-weight: 500;
  font-size: 45px;
  line-height: 58px;
  color: #00013a;
  margin-bottom: 45px;
}

.split-section-image-left__heading {
  font-family: "DomaineDisplay-DomaineDispMedium", serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 45px;
  color: #00013a;
  margin-bottom: 45px;
}

@media (min-width: 768px) {
  .split-section-image-left__heading {
    font-size: 70px;
    line-height: 82px;
  }
}

.split-section-image-left__text {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #323232;
}

.split-section-image-left--ways-we-help .split-section-image-left__img-col {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
}

.split-section-image-left--ways-we-help .split-section-image-left__img-col img {
  align-self: center;
}

.split-section-image-left--ways-we-help .split-section-image-left__title {
  font-family: "DomaineDisplay-DomaineDispMedium", serif;
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  color: #00013a;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.our-community__ways-we-help .split-section-image-left__img-col img {
  margin: 0 auto;
}

.our-community__ways-we-help .split-section-image-left__title {
  font-family: "BebasNeue-BebasNeueBold", sans-serif;
  font-weight: 600;
  font-size: 25px;
  line-height: 25px;
  color: #00013a;
  margin-top: 60px;
  margin-bottom: 45px;
}

@media (min-width: 768px) {
  .our-community__ways-we-help .split-section-image-left__title {
    font-size: 28px;
    line-height: 32px;
    margin-top: 0;
  }
}

.split-section-image-right {
  padding-top: 50px;
  padding-bottom: 50px;
}

.split-section-image-right__title {
  font-family: "DomaineDisplay-DomaineDispMedium", serif;
  font-weight: 500;
  font-size: 45px;
  line-height: 58px;
  color: #00013a;
  margin-bottom: 45px;
}

.split-section-image-right__heading {
  font-family: "DomaineDisplay-DomaineDispMedium", serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 45px;
  color: #00013a;
  margin-bottom: 45px;
}

@media (min-width: 768px) {
  .split-section-image-right__heading {
    font-size: 70px;
    line-height: 82px;
  }
}

.split-section-image-right__text {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #323232;
}

.split-section-image-right--our-story .split-section-image-right__title {
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  color: #00013a;
  margin-bottom: 20px;
}

.split-section-image-right__intro-section {
  font-family: "DomaineDisplay-DomaineDispMedium", serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 45px;
  color: #00013a;
}

@media (min-width: 768px) {
  .split-section-image-right__intro-section {
    font-size: 70px;
    line-height: 82px;
  }
}

.split-section-image-right--ways-we-help .split-section-image-right__img-col {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
}

.split-section-image-right--ways-we-help .split-section-image-right__img-col img {
  align-self: center;
}

.split-section-image-right--ways-we-help .split-section-image-right__title {
  font-family: "DomaineDisplay-DomaineDispMedium", serif;
  font-weight: 400;
  font-size: 28px;
  line-height: 32px;
  color: #00013a;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.financial-and-annual-reports__text.split-section-image-right__text, .shareholder-forms__text.split-section-image-right__text, .shareholder-enquiries__text.split-section-image-right__text {
  margin-bottom: 60px;
}

.our-community__ways-we-help .split-section-image-right__text-col {
  order: 13;
}

.our-community__ways-we-help .split-section-image-right__img-col {
  order: -1;
}

.our-community__ways-we-help .split-section-image-right__img-col img {
  margin: 0 auto;
}

@media (min-width: 768px) {
  .our-community__ways-we-help .split-section-image-right__text-col {
    order: -1;
  }
  .our-community__ways-we-help .split-section-image-right__img-col {
    order: 13;
  }
}

.our-community__ways-we-help .split-section-image-right__title {
  font-family: "BebasNeue-BebasNeueBold", sans-serif;
  font-weight: 600;
  font-size: 25px;
  line-height: 25px;
  color: #00013a;
  margin-top: 60px;
  margin-bottom: 45px;
}

@media (min-width: 768px) {
  .our-community__ways-we-help .split-section-image-right__title {
    font-size: 28px;
    line-height: 32px;
    margin-top: 0;
  }
}

.split-section-pdf-download-right {
  padding-top: 50px;
  padding-bottom: 50px;
}

.split-section-pdf-download-right__title {
  font-family: "DomaineDisplay-DomaineDispMedium", serif;
  font-weight: 500;
  font-size: 45px;
  line-height: 58px;
  color: #00013a;
  margin-bottom: 45px;
}

.split-section-pdf-download-right__heading {
  font-family: "DomaineDisplay-DomaineDispMedium", serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 45px;
  color: #00013a;
  margin-bottom: 45px;
}

@media (min-width: 768px) {
  .split-section-pdf-download-right__heading {
    font-size: 70px;
    line-height: 82px;
  }
}

.split-section-pdf-download-right__text {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #323232;
}

.split-section-text-both {
  padding-top: 50px;
  padding-bottom: 50px;
}

.split-section-text-both__title {
  font-family: "DomaineDisplay-DomaineDispMedium", serif;
  font-weight: 500;
  font-size: 45px;
  line-height: 58px;
  color: #00013a;
  margin-bottom: 45px;
}

.split-section-text-both__text {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #323232;
}

.property-details {
  padding-top: 0;
  padding-bottom: 30px;
}

.property-details__label {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #00013a;
  margin-bottom: 5px;
}

.property-details__detail {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #323232;
  margin-bottom: 40px;
}

.property-details__text {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #323232;
}

.gallery-slider {
  padding-top: 20px;
  padding-bottom: 100px;
}

@media (min-width: 768px) {
  .gallery-slider {
    padding-top: 50px;
  }
}

.gallery-slider .carousel-number-indicators {
  position: absolute;
  bottom: -51px;
}

.gallery-slider .carousel-indicators {
  bottom: -66px;
}

.gallery-slider .carousel-indicators li {
  background-color: #00013a;
  opacity: 1;
}

.gallery-slider .carousel-indicators li.active {
  background-color: #ff9e18;
}

.gallery-slider .carousel-control-next {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
  width: 12%;
}

.gallery-slider .carousel-control-prev {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
  width: 12%;
}

.gallery-slider .arrow {
  width: 30px;
  height: 30px;
  border-top: 3px solid #ffffff;
  border-right: 3px solid #ffffff;
}

.gallery-slider .arrow.right {
  left: 32%;
  transform: rotate(45deg);
}

.gallery-slider .arrow.left {
  left: 37%;
  transform: rotate(-135deg);
}

.parallax-background-image {
  background-attachment: fixed !important;
  background-size: cover !important;
  min-height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 768px) {
  .parallax-background-image {
    min-height: 400px;
  }
}

.parallax-background-image__title {
  font-family: "BebasNeue-BebasNeueBold", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 19px;
  letter-spacing: 2.22px;
  color: #ffffff;
  margin-bottom: 30px;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .parallax-background-image__title {
    font-size: 16px;
    line-height: 19px;
  }
}

.parallax-background-image__text {
  font-family: "DomaineDisplay-DomaineDispMedium", serif;
  font-weight: 500;
  font-size: 25px;
  line-height: 27px;
  color: #ffffff;
}

@media (min-width: 768px) {
  .parallax-background-image__text {
    font-size: 45px;
    line-height: 58px;
  }
}

.recent-news {
  padding-top: 20px;
  padding-bottom: 60px;
}

.recent-news__item a {
  text-decoration: none;
}

.recent-news__card {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
  -webkit-box-shadow: 0 5px 18px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 18px 3px rgba(0, 0, 0, 0.1);
  -webkit-transition: background 0.1s, -webkit-box-shadow 0.1s ease-out, -webkit-transform 0.1s ease-out;
  transition: background 0.1s, -webkit-box-shadow 0.1s ease-out, -webkit-transform 0.1s ease-out;
  -o-transition: box-shadow 0.1s ease-out, transform 0.1s ease-out, background 0.1s;
  transition: box-shadow 0.1s ease-out, transform 0.1s ease-out, background 0.1s;
  transition: box-shadow 0.1s ease-out, transform 0.1s ease-out, background 0.1s, -webkit-box-shadow 0.1s ease-out, -webkit-transform 0.1s ease-out;
  background: #ffffff;
  overflow: hidden;
}

.recent-news__card img {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

.recent-news__card:hover {
  -webkit-box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.25);
  -webkit-transform: translateY(3px);
  -ms-transform: translateY(3px);
  transform: translateY(3px);
  background: #f5f5f5;
}

.recent-news__card:hover img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

@media (min-width: 768px) {
  .recent-news__card {
    margin-bottom: 20px;
    flex-direction: column;
  }
}

.recent-news__card img {
  width: 100%;
  margin-bottom: auto;
  align-self: center;
}

.recent-news__card-img-wrapper {
  overflow: hidden;
  width: 40%;
}

@media (min-width: 768px) {
  .recent-news__card-img-wrapper {
    width: 100%;
  }
}

.recent-news__card-body {
  padding: 0 20px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 60%;
}

@media (min-width: 768px) {
  .recent-news__card-body {
    width: 100%;
    padding: 30px 20px 0 20px;
    min-height: 140px;
    justify-content: flex-start;
  }
}

.recent-news__card-body-category {
  display: none;
  font-family: "BebasNeue-BebasNeueBold", sans-serif;
  font-weight: 600;
  letter-spacing: 2px;
  color: #ff9e18;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 19px;
}

@media (min-width: 768px) {
  .recent-news__card-body-category {
    display: block;
  }
}

.recent-news__card-body-title {
  display: block;
  font-family: "DomaineDisplay-DomaineDispMedium", serif;
  font-weight: 500;
  color: #00013a;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 0;
  transition: all 0.3s ease-out;
}

.recent-news__card-img {
  transition: all 0.3s ease-out;
}

@media (min-width: 768px) {
  .recent-news__card {
    margin-bottom: 0;
  }
  .recent-news__card img {
    width: 100%;
  }
  .recent-news__item {
    position: relative;
  }
  .recent-news__card-body-text {
    display: block;
  }
}

.also-see {
  padding-top: 20px;
}

.also-see__card-main-link {
  display: inline-flex;
  text-decoration: none;
}

.also-see__card-main-link:hover, .also-see__card-main-link:focus, .also-see__card-main-link:active {
  text-decoration: none;
}

.also-see__card {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  box-shadow: 0 5px 18px 3px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.1s ease-out, transform 0.1s ease-out, background 0.1s;
  background: #ffffff;
}

.also-see__card:hover {
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.25);
  transform: translateY(3px);
  background: #f5f5f5;
}

.also-see__card img {
  width: 40%;
  margin-bottom: auto;
  align-self: center;
}

.also-see__card-img {
  transition: all 0.3s;
}

.also-see__card-body {
  padding: 0 20px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.also-see__card-body-title {
  font-family: "DomaineDisplay-DomaineDispMedium", serif;
  font-weight: 500;
  color: #00013a;
  font-size: 18px;
  line-height: 22px;
  margin-top: 20px;
  margin-bottom: 0;
}

.also-see__card-body-text {
  display: none;
}

@media (min-width: 768px) {
  .also-see__card-body-text {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #323232;
    margin-bottom: 60px;
    display: block;
  }
  .also-see__card-body-title {
    font-size: 45px;
    line-height: 58px;
    margin-bottom: 20px;
  }
  .also-see__card-body {
    padding: 0 90px;
  }
  .also-see__card {
    margin-bottom: 0;
  }
  .also-see__card img {
    width: 50%;
  }
}

.news-item {
  padding-top: 25px;
  padding-bottom: 25px;
}

@media (min-width: 768px) {
  .news-item {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.news-item__card {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  box-shadow: 0 5px 18px 3px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.1s ease-out, transform 0.1s ease-out, background 0.1s;
  background: #ffffff;
}

@media (min-width: 768px) {
  .news-item__card {
    margin-bottom: 20px;
  }
}

.news-item__card:hover {
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.25);
  transform: translateY(3px);
  background: #f5f5f5;
}

.news-item__card img {
  width: 100%;
  margin-bottom: 30px;
  align-self: center;
}

.news-item__card-main-link {
  display: inline-flex;
  flex-direction: column;
  text-decoration: none;
}

@media (min-width: 768px) {
  .news-item__card-main-link {
    flex-direction: row;
  }
}

.news-item__card-main-link:hover, .news-item__card-main-link:focus, .news-item__card-main-link:active {
  text-decoration: none;
}

.news-item__card-img {
  transition: all 0.3s ease-out;
}

.news-item__card-body {
  padding: 0 30px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.news-item__card-body-title {
  font-family: "DomaineDisplay-DomaineDispMedium", serif;
  font-weight: 400;
  color: #00013a;
  font-size: 25px;
  line-height: 30px;
}

.news-item__card-body-text {
  display: none;
}

.news-item__link {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .news-item__link {
    margin-bottom: 0;
  }
  .news-item__card-body-text {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #323232;
    margin-bottom: 40px;
    display: block;
  }
  .news-item__card-body {
    padding: 0 70px;
  }
  .news-item__card-body-title {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 10px;
  }
  .news-item__card {
    flex-direction: row;
    margin-bottom: 0;
  }
  .news-item__card img {
    width: 50%;
    margin-bottom: auto;
  }
}

@media (min-width: 768px) {
  .double-teaser__item:first-child {
    padding-right: 40px;
  }
  .double-teaser__item:last-child {
    padding-left: 40px;
  }
}

.double-teaser__card {
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  -webkit-box-shadow: 0 5px 18px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 18px 3px rgba(0, 0, 0, 0.1);
  -webkit-transition: background 0.1s, -webkit-box-shadow 0.1s ease-out, -webkit-transform 0.1s ease-out;
  transition: background 0.1s, -webkit-box-shadow 0.1s ease-out, -webkit-transform 0.1s ease-out;
  -o-transition: box-shadow 0.1s ease-out, transform 0.1s ease-out, background 0.1s;
  transition: box-shadow 0.1s ease-out, transform 0.1s ease-out, background 0.1s;
  transition: box-shadow 0.1s ease-out, transform 0.1s ease-out, background 0.1s, -webkit-box-shadow 0.1s ease-out, -webkit-transform 0.1s ease-out;
  background: #ffffff;
  overflow: hidden;
}

.double-teaser__card img {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

.double-teaser__card:hover {
  -webkit-box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.25);
  -webkit-transform: translateY(3px);
  -ms-transform: translateY(3px);
  transform: translateY(3px);
  background: #f5f5f5;
}

.double-teaser__card:hover img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

@media (min-width: 768px) {
  .double-teaser__card {
    margin-bottom: 20px;
  }
}

.double-teaser__card img {
  width: 100%;
}

.double-teaser__item-main-link:hover, .double-teaser__item-main-link:focus, .double-teaser__item-main-link:active {
  text-decoration: none;
}

.double-teaser__card-img-wrapper {
  overflow: hidden;
}

.double-teaser__card-img {
  transition: all 0.3s;
}

.double-teaser__card-body {
  padding: 15px 30px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .double-teaser__card-body {
    padding: 25px 48px 15px 48px;
  }
}

.double-teaser__card-body-title {
  font-family: "DomaineDisplay-DomaineDispMedium", serif;
  font-weight: 500;
  color: #00013a;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .double-teaser__card-body-title {
    margin-bottom: 12px;
  }
}

@media (min-width: 768px) {
  .double-teaser__card {
    margin-bottom: 0;
  }
}

.board-of-directors .collapse-container {
  margin-top: 30px;
  margin-bottom: 40px;
}

.board-of-directors .collapse-container__card-img {
  display: none;
}

@media (min-width: 768px) {
  .board-of-directors .collapse-container {
    margin-top: 50px;
    margin-bottom: 60px;
  }
  .board-of-directors .collapse-container__card-img {
    display: block;
  }
}

.board-of-directors__card {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  -webkit-box-shadow: 0 5px 18px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 5px 18px 3px rgba(0, 0, 0, 0.1);
  -webkit-transition: background 0.1s, -webkit-box-shadow 0.1s ease-out, -webkit-transform 0.1s ease-out;
  transition: background 0.1s, -webkit-box-shadow 0.1s ease-out, -webkit-transform 0.1s ease-out;
  -o-transition: box-shadow 0.1s ease-out, transform 0.1s ease-out, background 0.1s;
  transition: box-shadow 0.1s ease-out, transform 0.1s ease-out, background 0.1s;
  transition: box-shadow 0.1s ease-out, transform 0.1s ease-out, background 0.1s, -webkit-box-shadow 0.1s ease-out, -webkit-transform 0.1s ease-out;
  background: #ffffff;
  overflow: hidden;
}

.board-of-directors__card img {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

.board-of-directors__card:hover {
  -webkit-box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.25);
  -webkit-transform: translateY(3px);
  -ms-transform: translateY(3px);
  transform: translateY(3px);
  background: #f5f5f5;
}

.board-of-directors__card:hover img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

.board-of-directors__card img {
  width: 100%;
}

.board-of-directors__card-img-wrapper {
  overflow: hidden;
}

.board-of-directors__card-body-container {
  padding: 50px 0;
}

.board-of-directors__card-body {
  padding: 25px 30px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.board-of-directors__card-body-name {
  font-family: "BebasNeue-BebasNeueBold", sans-serif;
  font-weight: 600;
  color: #00013a;
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 0;
  text-transform: uppercase;
}

.board-of-directors__card-body-name-alt {
  font-family: "BebasNeue-BebasNeueBold", sans-serif;
  font-weight: 600;
  color: #ffffff;
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 0;
  text-transform: uppercase;
}

.board-of-directors__card-body-position {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #ff9e18;
}

.board-of-directors__card-body-position-alt {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #ff9e18;
  margin-bottom: 30px;
}

.board-of-directors__card-body-intro {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #ffffff;
}

.board-of-directors__card-body-bio {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.board-of-directors__card-body-email {
  margin-top: 2rem;
}

.board-of-directors__card-body-email-link {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  text-decoration: underline;
  transition: color 0.3s ease-out;
}

.board-of-directors__card-body-email-link:hover, .board-of-directors__card-body-email-link:focus, .board-of-directors__card-body-email-link:active {
  color: #ffffff;
  text-decoration: none;
}

@media (min-width: 768px) {
  .board-of-directors__card-body {
    padding: 40px 50px;
  }
  .board-of-directors__card-body-position-alt {
    margin-bottom: 40px;
  }
}

@media (min-width: 768px) {
  .board-of-directors__card {
    margin-bottom: 0;
  }
  .board-of-directors__card img {
    width: 100%;
  }
  .board-of-directors__card-body-container {
    padding: 130px 0;
  }
}

.board-of-directors .btn-close {
  position: absolute;
  right: 0;
  z-index: 1;
  right: 4px;
  top: 8px;
}

.board-of-directors .btn-close .icon {
  width: 20px;
  height: 20px;
}

.board-of-directors .btn-close .icon .icon__symbol {
  width: 20px;
  height: 20px;
}

@media (min-width: 768px) {
  .board-of-directors .btn-close {
    right: 16px;
    top: 18px;
  }
}

.board-of-directors .collapse-container {
  background: #00013a;
}

.board-of-directors .collapse {
  position: relative;
  width: 100%;
}

.board-of-directors .collapse--left:before {
  content: '';
  position: absolute;
  top: -44px;
  left: 7%;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  width: 0;
  height: 0;
  border-left: 35px solid transparent;
  border-right: 35px solid transparent;
  border-bottom: 35px solid #00013a;
  transition: all 0.5s;
  opacity: 1;
}

@media (min-width: 768px) {
  .board-of-directors .collapse--left:before {
    left: 1%;
  }
}

.board-of-directors .collapse--right:before {
  content: '';
  position: absolute;
  top: -44px;
  right: 7%;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
  width: 0;
  height: 0;
  border-left: 35px solid transparent;
  border-right: 35px solid transparent;
  border-bottom: 35px solid #00013a;
  transition: all 0.5s;
  opacity: 1;
}

@media (min-width: 768px) {
  .board-of-directors .collapse--right:before {
    left: 91%;
  }
}

.center-text-multiple {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header {
  padding-top: 2rem;
  padding-bottom: calc(10rem - 80px);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  height: 100vh;
}

@media (min-width: 768px) {
  .header {
    padding-top: 10rem;
  }
}

.header__category {
  font-family: "BebasNeue-BebasNeueBold", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #f99d2a;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.header__title {
  font-family: "DomaineDisplay-DomaineDispMedium", serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 45px;
  color: #ffffff;
}

@media (min-width: 768px) {
  .header__title {
    font-size: 70px;
    line-height: 82px;
  }
}

.header__scroll-page {
  display: none;
}

@media (min-width: 768px) {
  .header__scroll-page {
    display: block;
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
  }
}

.header.header__developments .header__title {
  font-weight: 400;
}

.header {
  padding-top: 50px;
  padding-bottom: calc(10rem - 80px);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

@media (min-width: 768px) {
  .header {
    padding-top: 50px;
  }
}

.header__category {
  font-family: "BebasNeue-BebasNeueBold", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #f99d2a;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.header__title {
  font-family: "DomaineDisplay-DomaineDispMedium", serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 45px;
  color: #ffffff;
}

@media (min-width: 768px) {
  .header__title {
    font-size: 70px;
    line-height: 82px;
  }
}

.header__scroll-page {
  display: none;
}

@media (min-width: 768px) {
  .header__scroll-page {
    display: block;
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
  }
}

.header .vimeo-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  pointer-events: none;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background-color: #ecebeb;
}

.header .vimeo-wrapper iframe {
  width: 100vw;
  height: 56.25vw;
  /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh;
  /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header.header__developments .header__title {
  font-weight: 400;
}

.social-list {
  list-style: none;
  display: flex;
  padding-left: 0;
}

.social-list .icon--circled {
  width: 40px;
  height: 40px;
  border: 0.2rem solid #00013a;
  border-radius: 50%;
  background: #00013a;
  transition: all 0.3s ease-out;
}

.social-list .icon--circled:hover, .social-list .icon--circled:focus, .social-list .icon--circled:active {
  border: 0.2rem solid #f99d2a;
  background: #f99d2a;
}

.social-list .icon--circled .icon__symbol {
  width: 20px;
  height: 20px;
}

.social-list__link {
  color: #ffffff;
}

.social-list__link:hover, .social-list__link:focus, .social-list__link:active {
  color: #ffffff;
}

.social-list__item {
  margin: 0 11px;
}

.footer .social-list {
  flex-direction: row;
  justify-content: flex-end;
}

.footer .social-list__item:last-child {
  margin-right: 0;
}

.table-pdf-download {
  background-color: #ffffff;
  margin-bottom: 60px;
}

.table-pdf-download thead {
  background-color: #00013a;
}

.table-pdf-download thead th {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  color: #ffffff;
  font-size: 18px;
  line-height: 21px;
  padding: 21px 10px 21px 15px;
  border-bottom: 0;
}

@media (min-width: 768px) {
  .table-pdf-download thead th {
    padding: 21px 10px 21px 32px;
  }
}

.table-pdf-download tbody tr:hover {
  background-color: #f5f5f5;
}

.table-pdf-download__file-title-text {
  width: 82%;
  display: inline-flex;
}

@media (min-width: 768px) {
  .table-pdf-download__file-title-text {
    width: 78%;
  }
}

.table-pdf-download__file-title-text:focus, .table-pdf-download__file-title-text:hover, .table-pdf-download__file-title-text:active {
  text-decoration: none;
}

.table-pdf-download__file-title-pdf {
  width: 18%;
  text-decoration: underline;
}

.table-pdf-download__file-title-pdf .icon-circled {
  margin-left: 0;
}

@media (min-width: 768px) {
  .table-pdf-download__file-title-pdf {
    width: 22%;
  }
  .table-pdf-download__file-title-pdf .icon-circled {
    margin-left: 15px;
  }
}

.table-pdf-download__file-title-pdf:focus, .table-pdf-download__file-title-pdf:hover, .table-pdf-download__file-title-pdf:active {
  text-decoration: underline;
}

.table-pdf-download__file-btn-text {
  display: none;
}

@media (min-width: 768px) {
  .table-pdf-download__file-btn-text {
    display: inline;
    transition: color 0.3s ease-out;
  }
}

.table-pdf-download td {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #00013a;
  vertical-align: middle;
}

@media (min-width: 768px) {
  .table-pdf-download td {
    font-size: 18px;
    padding-left: 32px;
  }
}

.table-pdf-download td a {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #00013a;
  text-decoration: underline;
  color: #00013a;
  width: 100%;
  text-decoration: none;
  text-align: left;
}

@media (min-width: 768px) {
  .table-pdf-download td a {
    font-size: 18px;
  }
}

.table-pdf-download.table-pdf-report-download .table-pdf-download__report-type-text {
  width: 50%;
}

.table-pdf-download.table-pdf-report-download .table-pdf-download__file-btn-pdf {
  width: 30%;
}

.table-pdf-download.table-pdf-report-download .table-pdf-download__year-text {
  width: 20%;
  text-align: left;
}

.table-pdf-download.table-pdf-forms-download .table-pdf-download__file-title-section {
  width: 90%;
  padding-right: 30px;
}

.table-pdf-download.table-pdf-forms-download .table-pdf-download__file-title {
  display: block;
  margin-bottom: 20px;
}

.table-pdf-download.table-pdf-forms-download .table-pdf-download__file-info-text {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #323232;
  display: block;
}

.table-pdf-download.table-pdf-forms-download .table-pdf-download__file-title-pdf {
  width: 20%;
  margin-bottom: auto;
}

.background-graphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: top left;
  background-repeat: repeat-y;
  background-size: cover;
  z-index: -1;
}

.intro {
  padding-top: 50px;
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .intro {
    padding-top: 120px;
    padding-bottom: 40px;
  }
}

.intro__heading {
  font-family: "DomaineDisplay-DomaineDispMedium", serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 45px;
  color: #00013a;
}

@media (min-width: 768px) {
  .intro__heading {
    font-size: 70px;
    line-height: 82px;
  }
}

.pagination-table {
  text-align: right;
}

.pagination-table__prev {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #00013a;
  font-size: 16px;
  line-height: 23px;
}

.pagination-table__pages {
  list-style-type: none;
  display: inline-block;
  padding-left: 0;
  margin-right: 0;
}

@media (min-width: 768px) {
  .pagination-table__pages {
    margin-right: 70px;
  }
}

.pagination-table__pages-item {
  width: 30px;
  display: inline-block;
  height: 30px;
  text-align: center;
}

.pagination-table__pages-item--direction {
  width: auto;
}

.pagination-table__pages-item a {
  text-decoration: none;
}

.pagination-table__pages-item-link.page-number {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #00013a;
  font-size: 16px;
  line-height: 23px;
  align-items: center;
  width: 30px;
  height: 39px;
}

.pagination-table__pages-item-span.page-number {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #00013a;
  font-size: 1.6rem;
  line-height: 2.3rem;
  width: 30px;
  height: 39px;
}

.pagination-table__pages-item-link.page-number.current {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #f99d2a;
  font-size: 16px;
  line-height: 23px;
  width: 30px;
  height: 39px;
}

.pagination-table__pages-item-span.page-number.current {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #f99d2a;
  font-size: 16px;
  line-height: 23px;
  width: 39px;
  height: 39px;
}

.pagination-table__next {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #00013a;
  font-size: 16px;
  line-height: 23px;
}

.single-news__article-header .full-width-image {
  padding-bottom: 50px;
}

.center-text-single {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-expandable {
  flex-direction: column;
}

.text-expandable__button-content {
  display: flex;
  align-items: center;
}

.text-expandable__text {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #323232;
}

.text-expandable__button {
  display: block;
}

.text-expandable__text--board-of-directors {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #ffffff;
  font-size: 16px;
  line-height: 24px;
}

.center-text-single .text-expandable__text {
  font-family: "DomaineDisplay-DomaineDispMedium", serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #00013a;
  text-align: center;
}

.news__intro h1 {
  font-family: "DomaineDisplay-DomaineDispMedium", serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 45px;
  color: #00013a;
  margin-top: 30px;
}

@media (min-width: 768px) {
  .news__intro h1 {
    font-size: 70px;
    line-height: 82px;
  }
}

.single-news__article-intro-title {
  font-family: "DomaineDisplay-DomaineDispMedium", serif;
  font-weight: 500;
  font-size: 45px;
  line-height: 58px;
  color: #00013a;
  margin-bottom: 30px;
}

.single-news__article-intro-author {
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .single-news__article-intro-author {
    margin-bottom: 50px;
  }
}

.single-news__article-intro-author-name-by {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #00013a;
  margin-left: 0;
}

@media (min-width: 768px) {
  .single-news__article-intro-author-name-by {
    margin-left: 25px;
  }
}

.single-news__article-intro-author-name-main {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #00013a;
}

.single-news__article-content-main-image {
  padding-top: 40px;
  padding-bottom: 40px;
}

.single-news__article-content-main {
  padding-top: 25px;
  padding-bottom: 25px;
}

.contact {
  padding-top: 50px;
}

.contact__list {
  list-style: none;
  padding-left: 0;
  padding-top: 30px;
}

.contact__list-item {
  line-height: 28px;
  display: flex;
  margin-bottom: 10px;
}

.contact__list-item a {
  color: #00013a;
  transition: all 0.3s;
  text-decoration: underline;
}

.contact__list-item a:hover, .contact__list-item a:focus, .contact__list-item a:active {
  color: #f99d2a;
  text-decoration: underline;
}

.contact__list-item-title {
  width: 30%;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #00013a;
}

@media (min-width: 768px) {
  .contact__list-item-title {
    width: 140px;
  }
}

.contact__list-item-info {
  display: inline-block;
}

.contact .social-list {
  padding-top: 20px;
}

.btn {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #00013a;
  text-decoration: none;
  text-align: center;
  margin: 0;
  cursor: pointer;
  padding: 10px 0;
  transition: all 0.3s ease-out;
  border-radius: 0;
}

.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: none;
}

.btn-link.btn-link--blue {
  color: #00013a;
}

.btn-link.btn-link--blue .icon-circled {
  border: 2px solid #ff9e18;
}

.btn-link.btn-link--blue .icon-circled .arrow {
  border-top: 2px solid #ff9e18;
  border-right: 2px solid #ff9e18;
}

.btn-link.btn-link--blue:hover, .btn-link.btn-link--blue:focus, .btn-link.btn-link--blue:active {
  text-decoration: none;
  color: #f99d2a;
}

.btn-link.btn-link--white {
  color: #ffffff;
}

.btn-link.btn-link--white .icon-circled {
  border: 2px solid #ff9e18;
}

.btn-link.btn-link--white .icon-circled .arrow {
  border-top: 2px solid #ff9e18;
  border-right: 2px solid #ff9e18;
}

.btn-link.btn-link--white:hover, .btn-link.btn-link--white:focus, .btn-link.btn-link--white:active {
  text-decoration: none;
  color: #f99d2a;
}

.btn-link:hover, .btn-link:focus, .btn-link:active {
  text-decoration: none;
  color: #00013a;
}

.btn-download {
  background-color: #ff9e18;
  border-radius: 20px;
  color: #ffffff;
  width: 180px;
  height: 45px;
  padding: 0 25px;
}

.btn-download:hover, .btn-download:focus, .btn-download:active {
  text-decoration: none;
  color: #ffffff;
}

.btn-text-expandable .icon-circled {
  border: 2px solid #f99d2a;
  color: #f99d2a;
}

.table-pdf-download .btn:hover .table-pdf-download__file-btn-text, .table-pdf-download .btn:focus .table-pdf-download__file-btn-text, .table-pdf-download .btn:active .table-pdf-download__file-btn-text {
  color: #f99d2a;
  text-decoration: underline;
}

.icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
}

.icon__symbol {
  display: block;
  width: 15px;
  height: 15px;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.icon--circled {
  width: 25px;
  /* ensure right proportions with respect to icon size */
  height: 25px;
  border: 2px solid currentColor;
  border-radius: 50%;
}

.icon-circled {
  width: 30px;
  height: 30px;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: 15px;
  transition: 300ms ease-out;
  border: 2px solid #ff9e18;
}

.icon-circled .arrow {
  position: absolute;
  top: 35%;
  left: 32%;
  border-top: 2px solid #f99d2a;
  border-right: 2px solid #f99d2a;
  border-radius: 0;
  width: 30%;
  height: 30%;
  transform: rotate(45deg);
  transition: 200ms ease-out;
}

.icon-circled .arrow.down {
  left: 38%;
  transform: rotate(135deg);
}

.icon-circled .arrow.left {
  left: 37%;
  transform: rotate(-135deg);
}

.icon-circled .arrow.up {
  left: 36%;
  top: 40%;
  transform: rotate(315deg);
}

.btn:hover .icon-circled {
  transform: translateX(10px);
}

.btn:hover .icon-circled--no-hover {
  transform: none;
}

.btn-download {
  -webkit-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s;
  position: relative;
}

.btn-download:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #3f51b5;
  z-index: -1;
  border-radius: inherit;
  opacity: 0;
  -webkit-transform: scale3d(0.6, 0.6, 1);
  transform: scale3d(0.6, 0.6, 1);
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}

.btn-download:hover {
  background-color: #00013a;
}

.btn-download:hover::before {
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
}

.btn-download:before {
  border-color: #7986cb;
}

.btn-download .icon-circled {
  width: 25px;
  height: 25px;
  margin-left: 25px;
  border: 2px solid #ffffff;
}

.btn-download .icon-circled .arrow {
  border-top: 2px solid #ffffff;
  border-right: 2px solid #ffffff;
}

.btn-back-to-top .icon-circled {
  width: 40px;
  height: 40px;
  margin-left: 0;
  border: 2px solid #00013a;
}

.btn-back-to-top .icon-circled .arrow {
  border-top: 2px solid #00013a;
  border-right: 2px solid #00013a;
}

.our-community .our-community__ways-we-help-container .container {
  position: relative;
}

.our-community .our-community__ways-we-help-container .container:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 15px;
  height: 2px;
  background: #DADADA;
  width: calc(100% - 30px);
}

.our-community .our-community__ways-we-help-container--no-bottom-border .container:after {
  display: none;
}

.our-people__main-image {
  padding-top: 50px;
}

.investors__share-price-section {
  padding-top: 80px;
  padding-bottom: 80px;
}

.investors__share-price-title {
  font-family: "DomaineDisplay-DomaineDispMedium", serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: #00013a;
}

.investors__share-price-container {
  height: 74px;
}

.investors__share-price {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 50px;
  line-height: 58px;
  color: #ff9e18;
  display: inline-block;
}

.investors__share-price-change {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #00013a;
  margin-left: 15px;
  display: inline-block;
}

.investors__share-price-info {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #00013a;
  display: block;
}

.investors__share-price-disclaimer {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #00013a;
}

.investors__link {
  margin-top: 100px;
}

.investors__link-tile {
  display: inline-block;
  text-align: center;
  overflow: hidden;
}

.investors__link-tile img {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  -o-transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

.investors__link-tile:hover {
  -webkit-box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.25);
  -webkit-transform: translateY(3px);
  -ms-transform: translateY(3px);
  transform: translateY(3px);
  background: #f5f5f5;
}

.investors__link-tile:hover .investors__tile-img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
}

.investors__tile {
  position: relative;
}

.investors__tile-img {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: transform 0.3s;
  -o-transition: transform 0.3s;
  transition: transform 0.3s;
}

.investors__tile-title {
  font-family: "BebasNeue-BebasNeueBold", sans-serif;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: 2px;
  color: #ffffff;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 60%;
}

.investors__tile-title:hover, .investors__tile-title:focus, .investors__tile-title:active {
  text-decoration: none;
  color: #ffffff;
}

.financial-and-annual-reports {
  padding-top: 0;
  padding-bottom: 0;
}

@media (min-width: 768px) {
  .financial-and-annual-reports {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.financial-and-annual-reports__img-col {
  padding-bottom: 50px;
}

@media (min-width: 768px) {
  .financial-and-annual-reports__img-col {
    padding-bottom: 0;
  }
}

.asx-announcements__file-download-section {
  padding-top: 0;
  padding-bottom: 0;
}

@media (min-width: 768px) {
  .asx-announcements__file-download-section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.asx-announcements__year-filter-item {
  width: 24%;
  height: 46px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  display: inline-block;
  color: #323232;
  text-align: center;
  padding: 10px 10px;
  -webkit-transition: color 0.3s;
  -moz-transition: color 0.3s;
  -ms-transition: color 0.3s;
  -o-transition: color 0.3s;
  transition: color 0.3s;
}

@media (min-width: 768px) {
  .asx-announcements__year-filter-item {
    width: 123px;
    height: 53px;
    padding: 15px 10px;
  }
}

.asx-announcements__year-filter-item:hover, .asx-announcements__year-filter-item:focus, .asx-announcements__year-filter-item:active {
  color: #f99d2a;
  text-decoration: none;
}

.asx-announcements__year-filter-item--is-active {
  color: #ffffff;
  background-color: #f99d2a;
}

.asx-announcements__year-filter-item--is-active:hover, .asx-announcements__year-filter-item--is-active:focus, .asx-announcements__year-filter-item--is-active:active {
  color: #ffffff;
}

.shareholder-enquiries__section-title {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #00013a;
  margin-bottom: 40px;
}

.shareholder-enquiries__contact-list {
  list-style: none;
  padding-left: 0;
}

.shareholder-enquiries__contact-list-item {
  line-height: 28px;
  display: flex;
}

.shareholder-enquiries__contact-list-item a {
  color: #00013a;
  transition: all 0.3s;
  text-decoration: underline;
}

.shareholder-enquiries__contact-list-item a:hover, .shareholder-enquiries__contact-list-item a:focus, .shareholder-enquiries__contact-list-item a:active {
  color: #f99d2a;
  text-decoration: underline;
}

.shareholder-enquiries__contact-list-item-title {
  width: 40%;
  display: inline-block;
}

@media (min-width: 768px) {
  .shareholder-enquiries__contact-list-item-title {
    width: 140px;
  }
}

.shareholder-enquiries__contact-list-item-info {
  display: inline-block;
}

.shareholder-enquiries__section-text a {
  color: #00013a;
  transition: all 0.3s;
  text-decoration: underline;
}

.shareholder-enquiries__section-text a:hover, .shareholder-enquiries__section-text a:focus, .shareholder-enquiries__section-text a:active {
  color: #f99d2a;
  text-decoration: underline;
}
