.header {
  padding-top: 50px;
  padding-bottom: calc(10rem - #{$navbar-height});
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  height: 100vh;
  position: relative;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    padding-top: 50px;
  }

  &__category {
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $color-orange;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  &__title {
    @include large-heading;
    color: $color-white;
  }

  &__scroll-page {
   display: none;

    @include media-breakpoint-up(md) {
      display: block;
      position: absolute;
      bottom: 0;
      text-align: center;
      width: 100%;
    }
  }

  .vimeo-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: 0;
    pointer-events: none;
    -ms-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background-color: $color-grey-background;
  }
  .vimeo-wrapper iframe {
    width: 100vw;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.header {

  &.header__developments {

    .header__title {
      font-weight: 400;
    }
  }
}