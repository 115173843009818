@font-face {
  font-family: 'BebasNeue-BebasNeueBold';
  src: url('../assets/fonts/BebasNeue/eot/BebasNeueBold.eot');
  src: url('../assets/fonts/BebasNeue/eot/BebasNeueBold.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/BebasNeue/woff/BebasNeueBold.woff') format('woff'),
  url('../assets/fonts/BebasNeue/ttf/BebasNeueBold.ttf') format('truetype'),
  url('../assets/fonts/FuturBebasNeueaNew/svg/BebasNeueBold.svg') format('svg');
  font-style: normal;
}

@font-face {
  font-family: 'DomaineDisplay-DomaineDispMedium';
  src: url('../assets/fonts/DomaineDisplay/eot/DomaineDispMedium.eot');
  src: url('../assets/fonts/DomaineDisplay/eot/DomaineDispMedium.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/DomaineDisplay/woff/DomaineDispMedium.woff') format('woff'),
  url('../assets/fonts/DomaineDisplay/ttf/DomaineDispMedium.ttf') format('truetype'),
  url('../assets/fonts/DomaineDisplay/svg/DomaineDispMedium.svg') format('svg');
  font-style: normal;
}

@font-face {
  font-family: 'DomaineDisplay-DomaineDispMedium-Italic';
  src: url('../assets/fonts/DomaineDisplay/eot/DomaineDispMedium-Italic.eot');
  src: url('../assets/fonts/DomaineDisplay/eot/DomaineDispMedium-Italic.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/DomaineDisplay/woff/DomaineDispMedium-Italic.woff') format('woff'),
  url('../assets/fonts/DomaineDisplay/ttf/DomaineDispMedium-Italic.ttf') format('truetype'),
  url('../assets/fonts/DomaineDisplay/svg/DomaineDispMedium-Italic.svg') format('svg');
  font-style: normal;
}

//$roboto: unquote('Roboto');

//@import url('https://fonts.googleapis.com/css?family=#{$roboto}:400,500,700'); // sass-lint:disable-line no-url-protocols

$font-family-roboto: 'Roboto', sans-serif;
$font-family-bebas-neue-bold: 'BebasNeue-BebasNeueBold', sans-serif;
$font-family-domaine-display-medium: 'DomaineDisplay-DomaineDispMedium', serif;
$font-family-domaine-display-medium-italic: 'DomaineDisplay-DomaineDispMedium-Italic', serif;

$font-family-sans-serif-small: $font-family-roboto;
$font-family-serif: $font-family-domaine-display-medium;
$font-family-serif-italic: $font-family-domaine-display-medium-italic;
$font-family-sans-serif: $font-family-bebas-neue-bold;

@mixin large-heading {
  font-family: $font-family-serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 45px;

  @include media-breakpoint-up(md) {
    font-size: 70px;
    line-height: 82px;
  }
}

@mixin normal-text {
  font-family: $font-family-sans-serif-small;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
