.split-section-image-left {
  padding-top: 50px;
  padding-bottom: 50px;

  &__title {
    font-family: $font-family-serif;
    font-weight: 500;
    font-size: 45px;
    line-height: 58px;
    color: $color-dark-blue;
    margin-bottom: 45px;
  }

  &__heading {
    @include large-heading;
    color: $color-dark-blue;
    margin-bottom: 45px;
  }

  &__text {
    @include normal-text;
    color: $color-grey-text;
  }

  &--ways-we-help {

    .split-section-image-left__img-col {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-line-pack: center;
      align-content: center;

      img {
        align-self: center;
      }
    }

    .split-section-image-left__title {
      font-family: $font-family-serif;
      font-weight: 400;
      font-size: 28px;
      line-height: 32px;
      color: $color-dark-blue;
      margin-bottom: 30px;
      text-transform: uppercase;
    }
  }
}

.our-community__ways-we-help {

  .split-section-image-left {

    &__img-col {

      img {
        margin: 0 auto;
      }
    }

    &__title {
      font-family: $font-family-sans-serif;
      font-weight: 600;
      font-size: 25px;
      line-height: 25px;
      color: $color-dark-blue;
      margin-top: 60px;
      margin-bottom: 45px;

      @include media-breakpoint-up(md) {
        font-size: 28px;
        line-height: 32px;
        margin-top: 0;
      }
    }
  }
}

