.board-of-directors {
  $self: &;

  .collapse-container {
    margin-top: 30px;
    margin-bottom: 40px;

    &__card-img {
      display: none;
    }

    @include media-breakpoint-up(md) {
      margin-top: 50px;
      margin-bottom: 60px;

      &__card-img {
        display: block;
      }
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    @include hover-shadow;

    img {
      width: 100%;
    }
  }

  &__card-img-wrapper {
    overflow: hidden;
  }

  &__card-body-container {
    padding: 50px 0;
  }

  &__card-body {
    padding: 25px 30px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: left;

    &-name {
      font-family: $font-family-sans-serif;
      font-weight: 600;
      color: $color-dark-blue;
      font-size: 28px;
      line-height: 32px;
      margin-bottom: 0;
      text-transform: uppercase;
    }

    &-name-alt {
      font-family: $font-family-sans-serif;
      font-weight: 600;
      color: $color-white;
      font-size: 28px;
      line-height: 32px;
      margin-bottom: 0;
      text-transform: uppercase;
    }

    &-position {
      @include normal-text;
      font-weight: 500;
      color: $color-light-orange;
    }

    &-position-alt {
      @include normal-text;
      font-weight: 500;
      color: $color-light-orange;
      margin-bottom: 30px;
    }

    &-intro {
      @include normal-text;
      font-weight: 600;
      color: $color-white;
    }

    &-bio {
      @include normal-text;
      color: $color-white;
    }

    &-email {
      margin-top: 2rem;
    }

    &-email-link {
      @include normal-text;
      color: $color-white;
      text-decoration: underline;
      transition: color 0.3s ease-out;

      &:hover, &:focus, &:active {
        color: $color-white;
        text-decoration: none;
      }
    }

    @include media-breakpoint-up(md) {
      padding: 40px 50px;

      &-position-alt {
        margin-bottom: 40px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    &__card {
      margin-bottom: 0;

      img {
        width: 100%;
      }
    }

    &__card-body-container {
      padding: 130px 0;
    }
  }

  .btn-close {
    position: absolute;
    right: 0;
    z-index: 1;
    right: 4px;
    top: 8px;

    .icon {
      width: 20px;
      height: 20px;

      .icon__symbol {
        width: 20px;
        height: 20px;
      }
    }

    @include media-breakpoint-up(md) {
      right: 16px;
      top: 18px;
    }
  }

  .collapse-container {
    background: $color-dark-blue;
  }
  
  .collapse {
    position: relative;
    width: 100%;

   &--left:before {
     content: '';
     position: absolute;
     top: -44px;
     left: 7%;
     -webkit-transform: translateY(10px);
     transform: translateY(10px);
     width: 0;
     height: 0;
     border-left: 35px solid transparent;
     border-right: 35px solid transparent;
     border-bottom: 35px solid $color-dark-blue;
     transition: all 0.5s;
     opacity: 1;

     @include media-breakpoint-up(md) {
       left: 1%;
     }
   }

    &--right:before {
      content: '';
      position: absolute;
      top: -44px;
      right: 7%;
      -webkit-transform: translateY(10px);
      transform: translateY(10px);
      width: 0;
      height: 0;
      border-left: 35px solid transparent;
      border-right: 35px solid transparent;
      border-bottom: 35px solid $color-dark-blue;
      transition: all 0.5s;
      opacity: 1;

      @include media-breakpoint-up(md) {
        left: 91%;
      }
    }
  }
}