.split-section-pdf-download-right {
  $self: &;

  padding-top: 50px;
  padding-bottom: 50px;

  &__title {
    font-family: $font-family-serif;
    font-weight: 500;
    font-size: 45px;
    line-height: 58px;
    color: $color-dark-blue;
    margin-bottom: 45px;
  }

  &__heading {
    @include large-heading;
    color: $color-dark-blue;
    margin-bottom: 45px;
  }

  &__text {
    @include normal-text;
    color: $color-grey-text;
  }
}
