$color-light-orange: #ff9e18;
$color-dark-blue: #00013a;
$color-grey-text: #323232;
$color-white: #ffffff;
$color-orange: #f99d2a;
$color-grey-main-text: #4A4A4A;
$color-grey-background: #ecebeb;
$color-grey-light-text: #9B9B9B;
$color-grey-super-light: #DADADA;
$color-grey-light-hover: #f5f5f5;
