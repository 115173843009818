.shareholder-enquiries {

  &__section-title {
    font-family: $font-family-sans-serif-small;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: $color-dark-blue;
    margin-bottom: 40px;
  }

  &__contact-list {
    list-style: none;
    padding-left: 0;
  }

  &__contact-list-item {
    line-height: 28px;
    display: flex;

    a {
      color: $color-dark-blue;
      transition: all 0.3s;
      text-decoration: underline;

      &:hover, &:focus, &:active {
        color: $color-orange;
        text-decoration: underline;
      }
    }
  }

  &__contact-list-item-title {
    width: 40%;
    display: inline-block;

    @include media-breakpoint-up(md) {
      width: 140px;
    }
  }

  &__contact-list-item-info {
    display: inline-block;
  }

  &__section-text {

    a {
      color: $color-dark-blue;
      transition: all 0.3s;
      text-decoration: underline;

      &:hover, &:focus, &:active {
        color: $color-orange;
        text-decoration: underline;
      }
    }
  }
}