.double-teaser {
  $self: &;

  @include media-breakpoint-up(md) {
    &__item {

      &:first-child {
        padding-right: 40px;
      }

      &:last-child {
        padding-left: 40px;
      }
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
    @include hover-shadow;

    @include media-breakpoint-up(md) {
      margin-bottom: 20px;
    }

    img {
      width: 100%;
    }
  }

  &__item-main-link {

    &:hover, &:focus, &:active {
      text-decoration: none;
    }
  }

  &__card-img-wrapper {
    overflow: hidden;
  }

  &__card-img {
    transition: all 0.3s;
  }

  &__card-body {
    padding: 15px 30px;
    justify-content: center;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      padding: 25px 48px 15px 48px;
    }
  }

  &__card-body-title {
    font-family: $font-family-serif;
    font-weight: 500;
    color: $color-dark-blue;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      margin-bottom: 12px;
    }
  }

  @include media-breakpoint-up(md) {
    &__card {
      margin-bottom: 0;
    }
  }
}