.asx-announcements {

  &__file-download-section {
    padding-top: 0;
    padding-bottom: 0;

    @include media-breakpoint-up(md) {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }

  &__year-filter-item {
    width: 24%;
    height: 46px;
    font-family: $font-family-sans-serif-small;
    font-weight: 500;
    display: inline-block;
    color: $color-grey-text;
    text-align: center;
    padding: 10px 10px;
    -webkit-transition: color 0.3s;
    -moz-transition: color 0.3s;
    -ms-transition: color 0.3s;
    -o-transition: color 0.3s;
    transition: color 0.3s;

    @include media-breakpoint-up(md) {
      width: 123px;
      height: 53px;
      padding: 15px 10px;
    }

    &:hover, &:focus, &:active {
      color: $color-orange;
      text-decoration: none;
    }

    &--is-active {
      color: $color-white;
      background-color: $color-orange;

      &:hover, &:focus, &:active {
        color: $color-white;
      }
    }
  }
}