.pagination-table {
  text-align: right;

  &__prev {
    font-family: $font-family-sans-serif-small;
    font-weight: 400;
    color: $color-dark-blue;
    font-size: 16px;
    line-height: 23px;
  }

  &__pages {
    list-style-type: none;
    display: inline-block;
    padding-left: 0;
    margin-right: 0;

    @include media-breakpoint-up(md) {
      margin-right: 70px;
    }
  }

  &__pages-item {
    width: 30px;
    display: inline-block;
    height: 30px;
    text-align: center;

    &--direction {
      width: auto;
    }
  }

  &__pages-item a {
    text-decoration: none;
  }

  &__pages-item-link.page-number {
    font-family: $font-family-sans-serif-small;
    font-weight: 400;
    color: $color-dark-blue;
    font-size: 16px;
    line-height: 23px;
    align-items: center;
    width: 30px;
    height: 39px;
  }

  &__pages-item-span.page-number {
    font-family: $font-family-sans-serif-small;
    font-weight: 400;
    color: $color-dark-blue;
    font-size: 1.6rem;
    line-height: 2.3rem;
    width: 30px;
    height: 39px;
  }

  &__pages-item-link.page-number.current {
    font-family: $font-family-sans-serif-small;
    font-weight: 700;
    color: $color-orange;
    font-size: 16px;
    line-height: 23px;
    width: 30px;
    height: 39px;
  }

  &__pages-item-span.page-number.current {
    font-family: $font-family-sans-serif-small;
    font-weight: 700;
    color: $color-orange;
    font-size: 16px;
    line-height: 23px;
    width: 39px;
    height: 39px;
  }

  &__next {
    font-family: $font-family-sans-serif-small;
    font-weight: 400;
    color: $color-dark-blue;
    font-size: 16px;
    line-height: 23px;
  }
}






