.pagination {
  width: 100%;
  justify-content: center;
  margin-top: 40px;

  &__prev {
    font-family: $font-family-sans-serif-small;
    font-weight: 400;
    color: $color-dark-blue;
    font-size: 16px;
    line-height: 23px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  &__pages {
    list-style-type: none;
    display: flex;
    padding-left: 0;
  }

  &__pages-item {
    display: flex;
    flex: 1;
  }

  &__pages-item a {
    text-decoration: none;
  }

  &__pages-item-link.page-number {
    font-family: $font-family-sans-serif-small;
    font-weight: 400;
    color: $color-dark-blue;
    font-size: 16px;
    line-height: 23px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 39px;
    height: 39px;
  }

  &__pages-item-span.page-number {
    font-family: $font-family-sans-serif-small;
    font-weight: 400;
    color: $color-dark-blue;
    font-size: 16px;
    line-height: 23px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 39px;
    height: 39px;
  }

  &__pages-item-link.page-number.current {
    font-family: $font-family-sans-serif-small;
    font-weight: 400;
    color: $color-dark-blue;
    font-size: 16px;
    line-height: 23px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 39px;
    background: $color-white;
    height: 39px;
  }

  &__pages-item-span.page-number.current {
    font-family: $font-family-sans-serif-small;
    font-weight: 400;
    color: $color-dark-blue;
    font-size: 16px;
    line-height: 23px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 39px;
    background: $color-white;
    height: 39px;
  }

  &__next {
    font-family: $font-family-sans-serif-small;
    font-weight: 400;
    color: $color-dark-blue;
    font-size: 16px;
    line-height: 23px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}
