.btn {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  font-family: $font-family-sans-serif-small;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: $color-dark-blue;
  text-decoration: none;
  text-align: center;
  margin: 0;
  cursor: pointer;
  padding: 10px 0;
  transition: all 0.3s ease-out;
  border-radius: 0;

  &.focus, &:focus {
    outline: 0;
    box-shadow: none;
  }
}

.btn-link {

  //&.btn-link--white {
  //  color: $color-white;
  //
  //  .icon-circled {
  //    border: 2px solid $color-white;
  //
  //    .arrow {
  //      border-top: 2px solid $color-white;
  //      border-right: 2px solid $color-white;
  //    }
  //  }
  //
  //  &:hover, &:focus, &:active {
  //    text-decoration: none;
  //    color: $color-white;
  //  }
  //}

  &.btn-link--blue {
    color: $color-dark-blue;

    .icon-circled {
      border: 2px solid $color-light-orange;

      .arrow {
        border-top: 2px solid $color-light-orange;
        border-right: 2px solid $color-light-orange;
      }
    }

    &:hover, &:focus, &:active {
      text-decoration: none;
      color: $color-orange;
    }
  }

  &.btn-link--white {
    color: $color-white;

    .icon-circled {
      border: 2px solid $color-light-orange;

      .arrow {
        border-top: 2px solid $color-light-orange;
        border-right: 2px solid $color-light-orange;
      }
    }

    &:hover, &:focus, &:active {
      text-decoration: none;
      color: $color-orange;
    }
  }

  &:hover, &:focus, &:active {
    text-decoration: none;
    color: $color-dark-blue;
  }
}

.btn-download {
  background-color: $color-light-orange;
  border-radius: 20px;
  color: $color-white;
  width: 180px;
  height: 45px;
  padding: 0 25px;

  &:hover, &:focus, &:active {
    text-decoration: none;
    color: $color-white;
  }
}

.btn-text-expandable {

  .icon-circled {
    border: 2px solid $color-orange;
    color: $color-orange;
  }
}

.table-pdf-download {

  .btn {

    &:hover, &:focus, &:active {

      .table-pdf-download__file-btn-text {
        color: $color-orange;
        text-decoration: underline;
      }
    }
  }
}
