.social-list {
  list-style: none;
  display: flex;
  padding-left: 0;

  .icon--circled {
    width: 40px;
    height: 40px;
    border: 0.2rem solid $color-dark-blue;
    border-radius: 50%;
    background: $color-dark-blue;
    transition: all 0.3s ease-out;

    &:hover, &:focus, &:active {
      border: 0.2rem solid $color-orange;
      background: $color-orange;
    }

    .icon__symbol {
      width: 20px;
      height: 20px;
    }
  }

  &__link {
    color: $color-white;

    &:hover, &:focus, &:active {
      color: $color-white;
    }
  }

  &__item {
    margin: 0 11px;
  }
}

.footer {

  .social-list {
    flex-direction: row;
    justify-content: flex-end;

    &__item {

      &:last-child {
        margin-right: 0;
      }
    }
  }
}