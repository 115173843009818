/*
  Project: Desane Website
  Author: Kieran Jones
 */

//@import 'settings/*';
//@import 'tools/*';
//@import 'generic/*';
//@import 'elements/*';
//@import 'objects/*';
//@import 'components/*';
//@import 'utilities/*';
@import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '../../node_modules/bootstrap/scss/_functions.scss';
@import '../../node_modules/bootstrap/scss/_variables.scss';
@import '../../node_modules/bootstrap/scss/mixins/_breakpoints.scss';
@import 'custom/custom.scss';