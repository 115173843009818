.navbar {
  border-bottom: 1px solid white;
  height: 80px;
  background: $color-grey-background;
  transition: all 0.3s;

  .navbar-brand__img-white {
    display: none !important;
  }

  .hamburger-button {
    span {
      background-color: $color-dark-blue;
    }

    &:focus {
      outline: 0;
    }
  }

  &--transparent {
    background: transparent;

    .hamburger-button {

      &:before {
        color: $color-white;
      }

      span {
        background-color: $color-white;
      }
    }

    .navbar-brand__img-blue {
      display: none !important;
    }

    .navbar-brand__img-white {
      display: inline-block !important;
    }

    // Navbar styling applied when the page is scrolled
    &.navbar-scrolled {

      .navbar-brand__img-white {
        display: none !important;
      }

      .navbar-brand__img-blue {
        display: inline-block !important;
      }
    }
  }

  // Navbar styling applied when the page is scrolled
  &.navbar-scrolled {
    background-color: $color-white;
    height: 72px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    .hamburger-button {

      &:before {
        color: $color-dark-blue;
      }

      span {
        background-color: $color-dark-blue;
      }
    }

    .navbar-brand__img-white {
      display: none !important;
    }

    .navbar-brand__img-blue {
      display: inline-block !important;
    }
  }
}

.hamburger-button {
  width: 24px;
  height: 28px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  z-index: 100;
  padding: 0;
  background: none;
  border: 0;
  outline: 0;
  color: $color-white;
  font-size: 14px;
  line-height: 28px;

  &:before {
    content: 'Menu';
    position: absolute;
    left: -50px;
    top: -3px;
    color: $color-dark-blue;
  }

  span {
    background: $color-white;
    display: block;
    position: absolute;
    height: 2px;
    transform: rotate(0deg);
    transition: all 0.25s cubic-bezier(0.22, 0.61, 0.36, 1);
    opacity: 1;
    width: 100%;
    left: 0;
  }

  span:nth-child(1) {
    top: 6px;
  }

  span:nth-child(2) {
    top: 11px;
  }

  span:nth-child(3) {
    top: 16px;
  }
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(255,255,255,0.7);
  display: none;
  transition: all 0.2s;
  z-index: 12;

  &.is-active {
    display: block;
    opacity: 1;
  }
}
