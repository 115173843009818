.header {
  padding-top: 2rem;
  padding-bottom: calc(10rem - #{$navbar-height});
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  height: 100vh;

  @include media-breakpoint-up(md) {
    padding-top: 10rem;
  }

  &__category {
    font-family: $font-family-sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $color-orange;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  &__title {
    @include large-heading;
    color: $color-white;
  }

  &__scroll-page {
   display: none;

    @include media-breakpoint-up(md) {
      display: block;
      position: absolute;
      bottom: 0;
      text-align: center;
      width: 100%;
    }
  }
}

.header {

  &.header__developments {

    .header__title {
      font-weight: 400;
    }
  }
}